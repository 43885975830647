// import { getAudiometryApi } from "../../apis/audiometry.api";
import { getPatientApi, getPatientByIdApi } from "../../apis/patient.api";
import { SNACK_BAR_VARIETNS, actions } from "../../utils/constants";
import { calculateAge, unMapValues } from "../../utils/helper";
import { calculateIGI } from "../../utils/j10.helper";
import { Calculation } from "../../utils/Fig6Component";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { callApiAction } from "./commonAction";
import { callSnackBar } from "./snackbarAction";
import { dslv5 } from "../../utils/DSLv";

export const changeDeviceSideSelection = (value, side) => {
  return { type: actions.CHNAGE_DEVICE_SIDE_SELECTION, value, side };
};

export const changeDeviceSelection = (value, side) => {
  return { type: actions.CHANGE_DEVICE_SELECTION, value, side };
};

export const resetReducer = () => {
  return { type: actions.RESET_FITTING_REDUCER, side: LISTENING_SIDE.BOTH };
};

export const connectDevice = (
  hardwareData,
  deviceInfo,
  deviceObj,
  disconnectFun,
  side
) => {
  return {
    type: actions.CONNECT_DEVICE,
    hardwareData,
    deviceInfo,
    deviceObj,
    disconnectFun,
    side: side,
  };
};
export const applyForRemoteFitting = (side) => {
  return {
    type: actions.REMOTE_FITTING,
    remote: true,
    deviceInfo: { name: "Remote", id: "remote" },
    disconnectFun: () => {},
    side: side,
  };
};
export const changeRemarks = (value, side) => {
  return { type: actions.CHNAGE_REMARKS, value, side: side };
};

export const changeLoadinfMessages = (loading, messages, side) => {
  return { type: actions.CHNAGE_LOADING_VALUES, loading, messages, side: side };
};
export const changeDeviceCompatiblity = (value, side) => {
  return { type: actions.CHNAGE_DEVICE_COMPATIBLITY, value, side: side };
};
export const onWriteFunctionChange = (value, side) => {
  return { type: actions.CHANGE_WRITE_FUN, value, side: side };
};
export const changeProfileId = (value, side) => {
  return { type: actions.CHANGE_PROFILE_ID, value, side };
};

export const resetDevice = (side) => {
  return { type: actions.RESET_DEVICE, side: side };
};

export const read_only = () => {
  return { type: actions.READ_ONLY_CHANGED };
};

export const disconnectDevice = (side, flag) => {
  return async (dispatch, getState) => {
    if (!flag)
      dispatch(callSnackBar("Device Disconnected.", SNACK_BAR_VARIETNS.error));
    dispatch({ type: actions.DISCONNECT_DEVICE, side: side });
  };
};
export const resetToDefault = () => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.DISCONNECT_DEVICE });
  };
};
export const applyFeedbackCancellation = (val, isWhisleBlock, side) => {
  console.log("inside action");
  return {
    type: actions.APPLY_FEEDBACKCANCELATION,
    value: val,
    WhisleBlock: isWhisleBlock,
    side: side,
  };
};
export const applyWinNoiseBlock = (val, side) => {
  return { type: actions.APPLY_WINNOISEBLOCK, val, side: side };
};
export const changeByteValue = (byteIndex, val, side) => {
  // console.log("this is index",byteIndex,"and value is",val)
  return { type: actions.CHANGE_BYTE_VAL, byteIndex, val, side: side };
};
export const changeMultipleByteValue = (byteIndex = [], val = []) => {
  return { type: actions.CHANGE_MULTIPLE_BYTE_VAL, byteIndex, val };
};
export const changeDeviceArray = (val = "") => {
  return async (dispatch, getState) => {
    if (val.split(" ").length != 100) {
      dispatch(
        callSnackBar(
          "It must required 100 bit of data.",
          SNACK_BAR_VARIETNS.error
        )
      );
    } else {
      dispatch({ type: actions.CHANGE_BYTE_ARRAY_VAL, val: val.split(" ") });
    }
  };
  return;
};

export const changeMasterGain = (val, side) => {
  return { type: actions.CHANGE_MASTER_GAIN, val, side: side };
};
export const changeCompression = (val, side) => {
  return { type: actions.CHANGE_COMPRESSION, val, side: side };
};
export const changeWindNoiseReduction = (val, side) => {
  return { type: actions.CHANGE_WIND_NOISE_REDUCTION, val, side: side };
};
export const changeNoiseReduction = (val, side) => {
  return { type: actions.CHANGE_NOISE_REDUCTION, val, side: side };
};
export const changeSharpness = (val, side) => {
  return { type: actions.CHANGE_SHARPNESS, val, side: side };
};
export const changeSpeechRedcution = (val, side) => {
  return { type: actions.CHANGE_SPEECH_REDUCTION, val, side: side };
};
export const setAutofitData = (value, side) => {
  return { type: actions.SET_AUTO_FIT_DATA, value, side };
};

export const changeMode = (data, side) => {
  return { type: actions.CHANGE_MODE, data, side };
};

export const setModeData = (data, side) => {
  return { type: actions.SET_MODE_DATA, data, side };
};

export const autofitDevice = (
  id,
  onSuccess = () => {},
  onError = () => {},
  newFitting,
  selectAlgo,
  callback
) => {
  return async (dispatch, getState) => {
    const deviceSide = newFitting?.listening_side;
    const deviceType = newFitting?.device_type;

    console.log("this is deviceType", deviceType);
    console.log("this is deviceType", deviceSide);

    dispatch(
      callApiAction(
        async () => await getPatientByIdApi({ id, no_profile: true }),
        async (response) => {
          if (response) {
            const deviceTypePrefix = deviceType == DEVICES.GLASSES ? "b" : "";

            const side = deviceSide == LISTENING_SIDE.LEFT ? "l" : "r";
            let linearGainForBand = [];
            let linearGainForBand0;
            let linearGainForBand1;
            let linearGainForBand2;
            let linearGainForBand3;
            let linearGainForBand4;
            let linearGainForBand5;
            let linearGainForBand6;
            let linearGainForBand7;
            console.log("this is response", response);

            switch (selectAlgo) {
              case 1:
                let userData = await getPatientByIdApi({ id });
                const age = calculateAge(userData?.data?.dob);

                linearGainForBand0 = dslv5(
                  response[deviceTypePrefix + side + "f1"],
                  250,
                  age
                ); // calculating For audiogram freq 125
                linearGainForBand.push(linearGainForBand0);

                linearGainForBand1 = dslv5(
                  response[deviceTypePrefix + side + "f2"],
                  250,
                  age
                ); // calculating For audiogram freq 250
                linearGainForBand.push(linearGainForBand1);

                linearGainForBand2 = dslv5(
                  response[deviceTypePrefix + side + "f3"],
                  500,
                  age
                ); // calculating For audiogram freq 500
                linearGainForBand.push(linearGainForBand2);
                linearGainForBand3 = dslv5(
                  response[deviceTypePrefix + side + "f4"],
                  1000,
                  age
                ); // calculating For audiogram freq 1000
                linearGainForBand.push(linearGainForBand3);
                linearGainForBand4 = dslv5(
                  response[deviceTypePrefix + side + "f5"],
                  2000,
                  age
                ); // calculating For audiogram freq 1000
                linearGainForBand.push(linearGainForBand4);
                linearGainForBand5 = dslv5(
                  response[deviceTypePrefix + side + "f6"],
                  4000,
                  age
                ); // calculating For audiogram freq 2000
                linearGainForBand.push(linearGainForBand5);
                linearGainForBand6 = dslv5(
                  response[deviceTypePrefix + side + "f7"],
                  6000,
                  age
                ); // calculating For audiogram freq 4000
                linearGainForBand.push(linearGainForBand6);
                linearGainForBand7 = dslv5(
                  response[deviceTypePrefix + side + "f8"],
                  6000,
                  age
                ); // calculating For audiogram freq 4000
                linearGainForBand.push(linearGainForBand7);
                break;
              case 2:
                console.log("this is algorithem  NAL");
                break;
              case 3:
                console.log("this is algorithem  1/3 Gain");
                break;
              case 4:
                linearGainForBand0 = Calculation(
                  response[deviceTypePrefix + side + "f1"]
                ); // calculating For audiogram freq 125
                linearGainForBand.push(linearGainForBand0);
                linearGainForBand1 = Calculation(
                  response[deviceTypePrefix + side + "f2"]
                ); // calculating For audiogram freq 250
                linearGainForBand.push(linearGainForBand1);
                linearGainForBand2 = Calculation(
                  response[deviceTypePrefix + side + "f3"]
                ); // calculating For audiogram freq 500
                linearGainForBand.push(linearGainForBand2);
                linearGainForBand3 = Calculation(
                  response[deviceTypePrefix + side + "f4"]
                ); // calculating For audiogram freq 1000
                linearGainForBand.push(linearGainForBand3);
                linearGainForBand4 = Calculation(
                  response[deviceTypePrefix + side + "f5"]
                ); // calculating For audiogram freq 2000
                linearGainForBand.push(linearGainForBand4);
                linearGainForBand5 = Calculation(
                  response[deviceTypePrefix + side + "f6"]
                ); // calculating For audiogram freq 4000
                linearGainForBand.push(linearGainForBand5);
                linearGainForBand6 = Calculation(
                  response[deviceTypePrefix + side + "f7"]
                ); // calculating For audiogram freq 6000
                linearGainForBand.push(linearGainForBand6);
                linearGainForBand7 = Calculation(
                  response[deviceTypePrefix + side + "f8"]
                ); // calculating For audiogram freq 8000
                linearGainForBand.push(linearGainForBand7);
                break;

              default:
                break;
            }

            for (let i = 0; i <= 7; i++) {
              dispatch(
                changeByteValue(
                  20 + i,
                  Math.floor(linearGainForBand[i].ER * 10),
                  deviceSide
                )
              );
              dispatch(
                changeByteValue(
                  28 + i,
                  Math.floor(linearGainForBand[i].Eknee),
                  deviceSide
                )
              );
              dispatch(
                changeByteValue(
                  36 + i,
                  Math.floor(linearGainForBand[i].exp_end_knee),
                  deviceSide
                )
              );
              dispatch(
                changeByteValue(
                  44 + i,
                  Math.floor(linearGainForBand[i].Cknee),
                  deviceSide
                )
              );
              dispatch(
                changeByteValue(
                  52 + i,
                  Math.floor(linearGainForBand[i].CR * 10),
                  deviceSide
                )
              );
              dispatch(
                changeByteValue(
                  60 + i,
                  Math.floor(linearGainForBand[i].MPO),
                  deviceSide
                )
              );
            }

            console.log("this is call for auto fit");

            // dispatch(
            //   setAutofitData([
            //     linearGainForBand0,
            //     linearGainForBand1,
            //     linearGainForBand2,
            //     linearGainForBand3,
            //     linearGainForBand4,
            //     linearGainForBand5,
            //     linearGainForBand6,
            //     linearGainForBand7,
            //   ])
            // );

            callback();

            onSuccess();
          } else {
            onError("No Audiogram found");
          }
        },
        (err) => {
          onError(err);
        }
      )
    );
  };
};

// export const insituAutofitDevice = (
//   id,
//   onSuccess = () => {},
//   onError = () => {}
// ) => {
//   return async (dispatch, getState) => {
//     const deviceSide = getState().fitting?.device_side;

//     dispatch(
//       callApiAction(
//         async () => await getAudiometryApi({ patient_uid: id }),
//         async (response) => {
//           if (response) {
//             const deviceTypePrefix = "insitu_";

//             const side = deviceSide == LISTENING_SIDE.LEFT ? "l" : "r";

//             const linearGainForBand0 = calculateIGI(
//               response[deviceTypePrefix + side + "f1"]
//             ); // calculating For audiogram freq 125
//             const linearGainForBand1 = calculateIGI(
//               response[deviceTypePrefix + side + "f2"]
//             ); // calculating For audiogram freq 250
//             const linearGainForBand2 = calculateIGI(
//               response[deviceTypePrefix + side + "f3"]
//             ); // calculating For audiogram freq 500
//             const linearGainForBand3 = calculateIGI(
//               response[deviceTypePrefix + side + "f4"]
//             ); // calculating For audiogram freq 1000
//             const linearGainForBand4 = calculateIGI(
//               response[deviceTypePrefix + side + "f4"]
//             ); // calculating For audiogram freq 1000
//             const linearGainForBand5 = calculateIGI(
//               response[deviceTypePrefix + side + "f5"]
//             ); // calculating For audiogram freq 2000
//             const linearGainForBand6 = calculateIGI(
//               response[deviceTypePrefix + side + "f6"]
//             ); // calculating For audiogram freq 4000
//             const linearGainForBand7 = calculateIGI(
//               response[deviceTypePrefix + side + "f6"]
//             ); // calculating For audiogram freq 4000

//             dispatch(
//               setAutofitData([
//                 linearGainForBand0,
//                 linearGainForBand1,
//                 linearGainForBand2,
//                 linearGainForBand3,
//                 linearGainForBand4,
//                 linearGainForBand5,
//                 linearGainForBand6,
//                 linearGainForBand7,
//               ])
//             );

//             onSuccess();
//           } else {
//             onError("No Audiogram found");
//           }
//         },
//         (err) => {
//           onError(err);
//         }
//       )
//     );
//   };
// };

export const readDataFromDevice = (data, side, onSuccess = () => {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.READ_FROM_DEVICE, data, side: side, onSuccess });
    dispatch(
      callSnackBar("Device data read and set.", SNACK_BAR_VARIETNS.suceess)
    );
  };
};

//reading data from id
export const changeFittingData = (data, read_only, side) => {
  return { type: actions.CHANGE_FITTING_DATA, data, read_only, side: side };
};

export const Mark_value = (index, value) => {
  return { type: actions.CHANGE_MARK_VALUE, index, value };
};
