import { actions } from "../../utils/constants";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";

const initialState = {
  profile_id: null,
  connected: false,
  enabled: undefined,

  loadingMessage: "",
  loading: undefined,
  hardwareData: [],
  deviceInfo: {
    name: "",
    id: "",
  },

  eqValues: [0, 0, 0, 0, 0, 0, 0, 0],
  battery_level: null,
  ric_noise_reduction: { left: 3 },
  ric_feedback_cancellation:{},
  ric_mode: 0,
  deviceObj: {},
  writeFun: undefined,
  disconnectFun: undefined,
  device_type: DEVICES.RIC_OPTIMA,
  device_side: LISTENING_SIDE.LEFT, 
  read_only: false,
  listening_side: LISTENING_SIDE.LEFT,
};
const ricLeftFittingReducer = (state = initialState, action) => {
  if (action.side == LISTENING_SIDE.RIGHT) {
    return { ...state };
  }
  switch (action.type) {
    case actions.CONNECT_DEVICE:
      return {
        ...state,
        hardwareData: action.hardwareData,
        deviceInfo: action.deviceInfo,
        deviceObj: action.deviceObj,
        connected: true,
        read_only: false,
        disconnectFun: action.disconnectFun,
      };
    case actions.REMOTE_FITTING:
      return {
        ...state,
        remote: true,
        deviceInfo: action.deviceInfo,
        connected: true,
        read_only: false,
        disconnectFun: action.disconnectFun,
      };
    case actions.CHANGE_PROFILE_ID:
      return { ...state, profile_id: action.value };
    case actions.CHNAGE_LOADING_VALUES:
      return {
        ...state,
        loading: action.loading,
        loadingMessage: action.messages,
      };
    case actions.CHNAGE_DEVICE_COMPATIBLITY:
      return { ...state, enabled: action.value };
    case actions.CHANGE_WRITE_FUN:
      return { ...state, writeFun: action.value }; 
    case actions.DISCONNECT_DEVICE:
      return {
        ...initialState,
        enabled: state.enabled,
        data: state.data,
        remarks: state.remarks,
        device_type: state.device_type,
        device_side: state.device_side,
        read_only: false,
        connected: false,
        remote: false,
      };


      case actions.CHANGE_RIC_NOISE_REDUCTION:
        if (action.side === LISTENING_SIDE.LEFT) {
          console.log("Updating LEFT noise reduction in reducer:", action.value);
          return {
            ...state,
            ric_noise_reduction: { ...state.ric_noise_reduction, left: action.value },
          };
        }
        return state;
      
            
        case actions.CHANGE_RIC_FEEDBACK_CANCELLATION:
          if (action.side === LISTENING_SIDE.LEFT) {
            return {
              ...state,
              ric_feedback_cancellation: action.value, // Toggle boolean
            };
          }
          return state;
        
              case actions.CHANGE_RIC_MODE_LEFT:
                console.log("Updating LEFT mode in reducer:", action.mode);
                return {
                  ...state,
                  ric_mode: action.mode, // Correctly updating mode for LEFT
                };
            

              case actions.READ_MODE :
                  return {
                    ...state,
                    ric_mode: action.value, 
                  };
                
                    case actions.UPDATE_BATTERY_LEVEL:
                      if (action.side === LISTENING_SIDE.LEFT) {
                      return {
                        ...state,
                        battery_level: action.batteryPercentage, // Update battery percentage
                      };
                    }
                    return state;
                
                    case actions.SET_EQUALIZER:
                      if (action.side === LISTENING_SIDE.LEFT) {
                        return {
                          ...state,
                          eqValues: action.eqValues,
                        };
                      }
                      return state;   
              
    default:
      return { ...state };
  }
};
export default ricLeftFittingReducer;
