// import { getAudiometryApi } from "../../apis/audiometry.api";
import { getPatientApi, getPatientByIdApi } from "../../apis/patient.api";
import { SNACK_BAR_VARIETNS, actions } from "../../utils/constants";
import { calculateAge, unMapValues } from "../../utils/helper";
import { calculateIGI } from "../../utils/j10.helper";
import { Calculation } from "../../utils/Fig6Component";
import { DEVICES, LISTENING_SIDE } from "../../utils/patient.constants";
import { callApiAction } from "./commonAction";
import { callSnackBar } from "./snackbarAction";
import { dslv5 } from "../../utils/DSLv";
import WriteRicDataToDevice from "../../pages/fitting/RicFitting/WriteRicDataToDevice";
import ReadRicDataFromDevice from "../../pages/fitting/RicFitting/ReadRicDataToDevice";


export const connectDevice = (
  hardwareData,
  deviceInfo,
  deviceObj,
  disconnectFun,
  side
) => {
  return {
    type: actions.CONNECT_DEVICE,
    hardwareData,
    deviceInfo,
    deviceObj,
    disconnectFun,
    side: side,
  };
};

export const read_only = () => {
  return { type: actions.READ_ONLY_CHANGED };
};

export const disconnectDevice = (side, flag) => {
  return async (dispatch, getState) => {
    if (!flag)
      dispatch(callSnackBar("Device Disconnected.", SNACK_BAR_VARIETNS.error));
    dispatch({ type: actions.DISCONNECT_DEVICE, side: side });
  };
};
export const changeLoadinfMessages = (loading, messages, side) => {
    return { type: actions.CHNAGE_LOADING_VALUES, loading, messages, side: side };
  };

  export const changeDeviceCompatiblity = (value, side) => {
    return { type: actions.CHNAGE_DEVICE_COMPATIBLITY, value, side: side };
  };

  
  export const readBatteryLevel = (side, deviceObj) => {
    return async (dispatch) => {
      try {
        // Battery Read Command
        const command = "3C 42 41 54 54 45 52 59 3E 0D 0A";
  
        console.log(`Sending Battery Read Command to ${side} device:`, command);
  
        // Send command to device and get response
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (!response) {
          console.error(`No response received for ${side} device`);
          return;
        }
  
        console.log(`Received response from ${side} device:`, response);
  
        // Trim and split the response into parts
        const responseParts = response.trim().split(" ");
        
        console.log(`Parsed response parts:`, responseParts);
  
        if (responseParts.length < 1) {
          console.warn(`Invalid battery response from ${side} device:`, response);
          return;
        }
  
        // Extract first HEX value (which represents battery percentage)
        const batteryHex = responseParts[0]; // Byte 0 as battery percentage

        console.log(`Extracted Battery HEX value: ${batteryHex}`); // 🔍 Debugging Log
        
        const batteryPercentage = parseInt(batteryHex, 16); // Convert HEX -> Decimal
        
        console.log(`Battery Percentage after conversion: ${batteryPercentage}%`); // 🔍 Debugging Log
        
        if (isNaN(batteryPercentage)) {
          console.error(`Failed to convert battery value from Hex to Decimal: ${batteryHex}`);
          return;
        }
        
        // Final Log to Confirm Execution
        console.log(`🔋 Battery Percentage for ${side}: ${batteryPercentage}%`);
        
        // Dispatch Redux Action
        dispatch({
          type: actions.UPDATE_BATTERY_LEVEL,
          side,
          batteryPercentage,
        });
        
      } catch (error) {
        console.error(`Error reading battery level from ${side} device:`, error);
      }
    };
  };

  export const changeRicFeedbackCancellation = (value, side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        // Get the current mode from Redux state
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();

        let command = "";
  
        if (value === 0) {
          command = side === LISTENING_SIDE.LEFT ? `89 03 01 ${modeHex} 00` : `89 03 02 ${modeHex} 00`; // off Feedback cancellation
        } else if (value === 1) {
          command = side === LISTENING_SIDE.LEFT ? `89 03 01 ${modeHex} 01` : `89 03 02 ${modeHex} 01`; // On Feedback cancellation
        }
        // } else if (value === 2) {
        //   command = side === LISTENING_SIDE.LEFT ? `89 03 01 ${modeHex} 00` : `89 03 02 ${modeHex} 00`; // mid-Level Noise Reduction
        // }else if (value === 3) {
        //   command = side === LISTENING_SIDE.LEFT ? `89 03 01 ${modeHex} 00` : `89 03 02 ${modeHex} 00`; // High-Level Noise Reduction
        // }
  
        if (command) {
          console.log(`Sending Noise Reduction command: ${command}`);
          await WriteRicDataToDevice(command, side, deviceObj);
  
          dispatch({
            type: actions.CHANGE_RIC_NOISE_REDUCTION,
            value,
            side,
          });
        } else {
          console.warn("Invalid noise reduction value:", value);
        }
      } catch (error) {
        console.error("Error changing noise reduction:", error);
      }
    };
  };
  

  export const changeRicNoiseReduction = (value, side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        // Get the current mode from Redux state
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();

        let command = "";
  
        if (value === 0) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 00` : `87 03 02 ${modeHex} 00`; // off Noise Reduction
        } else if (value === 1) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 01` : `87 03 02 ${modeHex} 01`; // low-Level Noise Reduction
        } else if (value === 2) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 02` : `87 03 02 ${modeHex} 02`; // mid-Level Noise Reduction
        }else if (value === 3) {
          command = side === LISTENING_SIDE.LEFT ? `87 03 01 ${modeHex} 03` : `87 03 02 ${modeHex} 03`; // High-Level Noise Reduction
        }
  
        if (command) {
          console.log(`Sending Noise Reduction command: ${command}`);
          await WriteRicDataToDevice(command, side, deviceObj);
  
          dispatch({
            type: actions.CHANGE_RIC_NOISE_REDUCTION,
            value,
            side,
          });
        } else {
          console.warn("Invalid noise reduction value:", value);
        }
      } catch (error) {
        console.error("Error changing noise reduction:", error);
      }
    };
  };
  

  export const changeRicMode = (mode, deviceSide, deviceObj) => {
    return async (dispatch) => {
      try {
        let command = "";
  
        if (deviceSide === LISTENING_SIDE.RIGHT) {
          if (mode === 0) command = "85 03 02 00 01"; // Quiet Mode
          else if (mode === 1) command = "85 03 02 00 02"; // Noise Mode
          else if (mode === 2) command = "85 03 02 00 03"; // Outdoor Mode
        } else if (deviceSide === LISTENING_SIDE.LEFT) {
          if (mode === 0) command = "85 03 01 00 01"; // Quiet Mode
          else if (mode === 1) command = "85 03 01 00 02"; // Noise Mode
          else if (mode === 2) command = "85 03 01 00 03"; // Outdoor Mode
        }
  
        if (!command) {
          console.warn("Invalid mode selection:", mode);
          return;
        }
  
        console.log(`Setting mode ${mode} for ${deviceSide} with HEX command:`, command);
  
        if (deviceObj) {
          await WriteRicDataToDevice(command, deviceSide, deviceObj);
        }
  
        dispatch({
          type: deviceSide === LISTENING_SIDE.RIGHT ? actions.CHANGE_RIC_MODE_RIGHT : actions.CHANGE_RIC_MODE_LEFT,
          mode,
        });
      } catch (error) {
        console.error("Error changing RIC mode:", error);
      }
    };
  };
  

  export const readMode = (side, deviceObj) => {
    return async (dispatch) => {
      try {
        const command = side === LISTENING_SIDE.RIGHT ? "84 02 02 00" : "84 02 01 00";
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (!response || !response.startsWith("84 03")) {
          console.warn(`Invalid mode response: ${response}`);
          return;
        }
  
        const data = response.split(" ");
        const d1 = parseInt(data[4], 16); 
  
        // Correct mapping
        let mode;
        if (d1 === 1) mode = 0; // Mode 0 (Quiet Mode)
        else if (d1 === 2) mode = 1; // Mode 1 (Noise Mode)
        else if (d1 === 3) mode = 2; // Mode 2 (Outdoor Mode)
        else {
          console.error(`Unexpected mode value: ${d1}`);
          return;
        }
  
        console.log(`Mapped mode for ${side}: ${mode}`);
  
        dispatch({
          type: side === LISTENING_SIDE.RIGHT ? actions.CHANGE_RIC_MODE_RIGHT : actions.CHANGE_RIC_MODE_LEFT,
          mode,
        });
  
      } catch (error) {
        console.error("Error reading mode:", error);
      }
    };
  };
  export const readNoiseReduction = (side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
        const command = side === LISTENING_SIDE.RIGHT ? `86 02 02  ${modeHex}` : `86 02 01  ${modeHex}`;
        
        console.log(`Sending Read Command to ${side} device:`, command);
  
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (response && response.startsWith("86 03")) {
          const responseParts = response.split(" ");
          const responseSide = responseParts[2] === "01" ? LISTENING_SIDE.LEFT : LISTENING_SIDE.RIGHT;
          const noiseReductionLevel = parseInt(responseParts[4], 16);
  
          console.log(`Received Noise Reduction Level for ${responseSide}:`, noiseReductionLevel);
  
          dispatch({
            type: actions.CHANGE_RIC_NOISE_REDUCTION,
            side: responseSide,
            value: noiseReductionLevel,
          });
        } else {
          console.error(` Invalid response from ${side} device:`, response);
        }
      } catch (error) {
        console.error(` Error reading noise reduction level from ${side} device:`, error);
      }
    };
  };
  
  export const setEqualizer = (side, eqValues, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
        // Convert EQ values to HEX format
        const eqHexValues = eqValues?.map(value => {
          let hexValue;
          switch (value) {
            case 0:
              hexValue = "00";
              break;
            case -3:
              hexValue = "03";
              break;
            case -6:
              hexValue = "06";
              break;
            case -9:
              hexValue = "09";
              break;
            case -12:
              hexValue = "0C";
              break;
            case -15:
              hexValue = "0F";
              break;
            case -18:
              hexValue = "12";
              break;
            case -21:
              hexValue = "15";
              break;
            default:
              hexValue = "00"; 
          }
          return hexValue;
        });
  
        const command = side === LISTENING_SIDE.LEFT 
          ? `8B 0A 01 ${modeHex} ${eqHexValues.join(' ')}`
          : `8B 0A 02 ${modeHex} ${eqHexValues.join(' ')}`;
  
        console.log(`Sending EQ command: ${command}`);
        await WriteRicDataToDevice(command, side, deviceObj);
  
        dispatch({
          type: actions.SET_EQUALIZER,
          side,
          eqValues,
        });
      } catch (error) {
        console.error("Error setting equalizer:", error);
      }
    };
  };
  
  export const readEqualizer = (side, deviceObj) => {
    return async (dispatch, getState) => {
      try {
        const state = getState();
        const mode = side === LISTENING_SIDE.RIGHT ? state.ricRightFitting.ric_mode : state.ricLeftFitting.ric_mode;
  
        // Convert mode to HEX format (01, 02, 03)
        const modeHex = (mode + 1).toString(16).padStart(2, "0").toUpperCase();
        const command = side === LISTENING_SIDE.RIGHT ? `8A 02 02 ${modeHex}` : `8A 02 01 ${modeHex}`;
  
        console.log(`Sending Read EQ Command to ${side} device:`, command);
  
        const response = await ReadRicDataFromDevice(command, side, deviceObj);
  
        if (response && response.startsWith("8A 0A")) {
          const responseParts = response.split(" ");
          const eqValues = responseParts.slice(5, 12).map(value => parseInt(value, 16));
  
          console.log(`Received EQ Values for ${side}:`, eqValues);
  
          dispatch({
            type: actions.SET_EQUALIZER,
            side,
            eqValues,
          });
        } else {
          console.error(`Invalid response from ${side} device:`, response);
        }
      } catch (error) {
        console.error(`Error reading EQ values from ${side} device:`, error);
      }
    };
  };