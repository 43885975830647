import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Slider,
  Tab,
  Tabs,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DEVICES, LISTENING_SIDE } from "../../../utils/patient.constants";
import {
  changeByteValue,
  changeDeviceSideSelection,
} from "../../../store/actions/fittingAction";
import { ratios } from "../../../utils/ratios";
import { roundValueForCRAndER } from "../../../utils/helper";
import RicFittingChartArea from "./RicFittingChartArea";
import { setEqualizer } from "../../../store/actions/ricFittingActions";
import {
  changeRicFeedbackCancellation,
  changeRicNoiseReduction,
} from "../../../store/actions/ricFittingActions";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RicFineTunningController = ({
  setData8L,
  data8L,
  setData8R,
  data8R,
  mutedHandle,
  setMPOL32,
  setMPOR32,
  mpoR32,
  mpoL32,
  setFreShapR32,
  freShapR32,
  setFreShapL32,
  freShapL32,
  setShouldUpdate,
}) => {
  const [edgeVal, setEdgeVal] = useState(50);
  const { ricLeftFitting } = useSelector((state) => state);
  const { ricRightFitting } = useSelector((state) => state);

  const [value, setValue] = useState(0);
  const [activeSide, setActiveSide] = useState(
    ricRightFitting?.connected ? LISTENING_SIDE.RIGHT : LISTENING_SIDE.LEFT
  );
  const [link, setLink] = useState(false);

  const [eqValuesLeft, setEqValuesLeft] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const [eqValuesRight, setEqValuesRight] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  const eqValues =
    activeSide === LISTENING_SIDE.LEFT ? eqValuesLeft : eqValuesRight;

  const handleSliderChange = (index, value) => {
    if (activeSide === LISTENING_SIDE.LEFT) {
      const newEqValues = [...eqValuesLeft];
      newEqValues[index] = value;
      setEqValuesLeft(newEqValues);

      const deviceObj = ricLeftFitting.deviceObj;
      const mode = ricLeftFitting.ric_mode;

      dispatch(setEqualizer(activeSide, newEqValues, deviceObj));
    } else if (activeSide === LISTENING_SIDE.RIGHT) {
      const newEqValues = [...eqValuesRight];
      newEqValues[index] = value;
      setEqValuesRight(newEqValues);

      const deviceObj = ricRightFitting.deviceObj;
      const mode = ricRightFitting.ric_mode;

      dispatch(setEqualizer(activeSide,  newEqValues, deviceObj));
    }
  };
  const [sliderButton, setSliderButton] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setShouldUpdate(true);
    };
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const marks = [
    { value: 0, label: "Off" },
    { value: 1, label: "Low" },
    { value: 2, label: "Medium" },
    { value: 3, label: "High" },
    { value: 4, label: "Very High" },
    { value: 5, label: "Max" },
  ];

  const CustomSlider = styled(Slider)(({ side }) => ({
    backgroundColor: "white",

    height: 0.1,
    // '& .MuiSlider-track': {
    //   border: 'black  ',
    // },
    "& .MuiSlider-thumb": {
      height: 20,
      width: 20,
      boxShadow: "none",
      backgroundColor: side
        ? "rgba(238, 210, 210, 0.5 )"
        : "rgba(229, 238, 244, 0.5)",
      border: side ? "4px solid  #C24747" : "4px solid #1265A1",
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% + 4px)",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#DDDDDD",
      height: 10,
      width: 10,
      borderRadius: 20,

      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "white",
      },
    },
    "& .MuiSlider-markLabel": {
      color: "#808080",
      fontSize: "1vw",
      // fontWeight: 'bold',
      fontFamily: "League spartan",
    },
    "& .MuiSlider-markLabelActive": {
      color: "#2D3B67",
      // fontWeight: 'bold',
      fontFamily: "League spartan",
    },
  }));

  return (
    <>
      <Box>
        <Box maxHeight={"60vh"}>
          <Grid xs={12} md={12} container>
            <Grid md={5.7} xs={5.7}>
              <RicFittingChartArea
                mutedHandle={mutedHandle}
                fitting={ricRightFitting}
                edgeVal={eqValuesRight}
              />
            </Grid>
            <Grid md={0.6} xs={0.6}>
              <Box
                sx={{
                  marginTop: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#4A4A4A",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        50dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#969696",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        65dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#CCCCCC",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        80dB
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={5.7} md={5.7}>
              <RicFittingChartArea
                mutedHandle={mutedHandle}
                fitting={ricLeftFitting}
                edgeVal={eqValuesLeft}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Grid xs={12} display={"flex"} justifyContent={"center"} width={"60vw"}>
        {/* LINK Button */}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginLeft:'90px', marginRight:'90px', marginBottom:'10px' }}>
          <Box
            mt={2}
            sx={{
              width: "28vw",
              border: "1px solid #DDDDDD",
              borderRadius: "4px",
              // minHeight: "4vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "12px",
                  fontFamily: "League spartan",
                  fontWeight: "400",
                }}
              >
                Noise Reduction
              </Typography>
              <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                <CustomSlider
                  side={LISTENING_SIDE.RIGHT}
                  // disabled={fitting.read_only}
                  track={false}
                  value={ricRightFitting.ric_noise_reduction.right} // Using state
                  min={0}
                  max={3}
                  step={1}
                  marks={marks}
                  onChange={(event, value) => {
                    console.log(
                      "Device Object in UI before dispatch:",
                      ricRightFitting.deviceObj
                    );

                    dispatch(
                      changeRicNoiseReduction(
                        value,
                        LISTENING_SIDE.RIGHT,
                        ricRightFitting.deviceObj
                      )
                    );
                  }}
                  valueLabelDisplay="off"
                />
              </Box>
            </Box>
          </Box>
          <Box
            mt={2}
            sx={{
              width: "28vw",
              border: "1px solid #DDDDDD",
              borderRadius: "4px",
              // minHeight: "4vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: "12px",
                    fontFamily: "League spartan",
                    fontWeight: "400",
                  }}
                >
                  Noise Reduction
                </Typography>
                <CustomSlider
                  // disabled={fitting.read_only}
                  track={false}
                  value={ricLeftFitting.ric_noise_reduction.left} // Use direct state
                  min={0}
                  max={3}
                  step={1}
                  marks={marks}
                  onChange={(event, value) => {
                    console.log(
                      "Device Object in UI before dispatch:",
                      ricLeftFitting.deviceObj
                    );

                    dispatch(
                      changeRicNoiseReduction(
                        value,
                        LISTENING_SIDE.LEFT,
                        ricLeftFitting.deviceObj || {}
                      )
                    );
                  }}
                  valueLabelDisplay="off"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      <Box
        // ml={5}
        mt={1}
        sx={{
          height: "20.5vh",
          scrollbarWidth: "none",
          width: "86vw",
          // overflow:"auto"
        }}
      >
        <Grid
          container
          xs={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box
            onClick={() => {
              setActiveSide(LISTENING_SIDE.RIGHT);
            }}
            sx={{
              visibility:
                activeSide == LISTENING_SIDE.RIGHT ||
                !ricRightFitting?.connected
                  ? "hidden"
                  : "",
              cursor: "pointer",
              backgroundColor: "#C24747",
              height: "13vh",
              width: "2vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Button  sx={{ height: "13vh", width: "1vw" }} > */}
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 17.5L1.5 9.5L9.5 1.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* </Button> */}
          </Box>

          {((activeSide == LISTENING_SIDE.LEFT &&
            ricLeftFitting.device_type == DEVICES.RIC_OPTIMA) ||
            (activeSide == LISTENING_SIDE.RIGHT &&
              ricRightFitting.device_type == DEVICES.RIC_OPTIMA)) && (
            <>
              {eqValues.map((value, index) => (
                <Grid item xs={1.35} md={1.35} key={index}>
                  <Box
                    onClick={() => {
                      !(
                        ricRightFitting.read_only || ricLeftFitting.read_only
                      ) && setSliderButton(index);
                    }}
                    sx={{
                      cursor: "pointer",
                      border: "1px solid #DDDDDD",
                      height: "5vh",
                      width: "10.2vw",
                      backgroundColor:
                        sliderButton === index
                          ? activeSide === LISTENING_SIDE.LEFT
                            ? "rgba(246, 251, 254, 1)"
                            : "rgba(252, 247, 247, 1)"
                          : "",
                      borderTop:
                        sliderButton === index
                          ? activeSide === LISTENING_SIDE.LEFT
                            ? "8px solid rgba(18, 101, 161, 1)"
                            : "8px solid #C24747"
                          : "8px solid #DDDDDD",
                    }}
                  >
                    <Typography
                      sx={{ display: "flex", justifyContent: "center" }}
                    ></Typography>
                  </Box>
                  <Grid
                    sx={{
                      backgroundColor:
                        sliderButton === index
                          ? activeSide === LISTENING_SIDE.LEFT
                            ? "rgba(246, 251, 254, 1)"
                            : "rgba(252, 247, 247, 1)"
                          : "",
                      border: "1px solid #DDDDDD",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "10.2vw",
                      height: "25vh",
                      scrollbarWidth: "none",
                    }}
                  >
                    <Slider
                      disabled={
                        ricRightFitting.read_only || ricLeftFitting.read_only
                      }
                      sx={{
                        height: "20vh",
                        color:
                          activeSide === LISTENING_SIDE.LEFT
                            ? "#1265A1"
                            : "#C24747",
                      }}
                      orientation="vertical"
                      valueLabelDisplay="auto"
                      min={-21}
                      max={0}
                      step={3}
                      value={value}
                      onChange={(e, newValue) =>
                        handleSliderChange(index, newValue)
                      }
                    />
                  </Grid>
                  <Typography>
                    {["250", "500", "1k", "2k", "3k", "4k", "6k", "8k"][index]}
                  </Typography>
                </Grid>
              ))}
            </>
          )}

          <Box
            ml={2}
            onClick={() => {
              setActiveSide(LISTENING_SIDE.LEFT);
            }}
            sx={{
              visibility:
                activeSide == LISTENING_SIDE.RIGHT
                  ? ricLeftFitting?.connected
                    ? ""
                    : "hidden"
                  : "hidden",
              cursor: "pointer",
              backgroundColor: "#1265A1",
              height: "13vh",
              width: "2vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <Button  sx={{ height: "13vh", width: "1vw" }}> */}
            <svg
              width="11"
              height="19"
              viewBox="0 0 11 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 17.5L9.5 9.5L1.5 1.5"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {/* </Button> */}
          </Box>
        </Grid>
        {/* <Box mt={1} gap={4} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={ricRightFitting.read_only || ricLeftFitting.read_only}
              sx={{ width: "100px", height: "5vh" }}
              variant="outlined"
            >
              <KeyboardArrowUpIcon fontSize="large" />
            </Button>
            <Button
              disabled={ricRightFitting.read_only || ricLeftFitting.read_only}
              sx={{ width: "100px", height: "5vh" }}
              variant="outlined"
            
            >
              <KeyboardArrowDownIcon fontSize="large" />
            </Button>
          </Box> */}
        
      </Box>
    </>
  );
};

export default RicFineTunningController;
