import React, { useState, useEffect } from 'react';
import { Button, Box, CircularProgress, Typography, Divider, Slider } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { LISTENING_SIDE } from '../../utils/patient.constants';
import { readMode, readNoiseReduction } from '../../store/actions/ricFittingActions';
import { useDispatch } from 'react-redux';

const hexStringToUint8Array = (hexString) => {
  const bytes = hexString.trim().split(' ').map(byte => parseInt(byte, 16));
  return new Uint8Array(bytes);
};

const writeDataToCharacteristic = async (characteristic, data) => {
  try {
    const value = hexStringToUint8Array(data);
    console.log('Converted HEX data to Uint8Array:', value);

    if (characteristic.properties.writeWithoutResponse) {
      await characteristic.writeValueWithoutResponse(value);
      console.log('Data written to characteristic (without response):', data);
    } else if (characteristic.properties.write) {
      await characteristic.writeValue(value);
      console.log('Data written to characteristic (with response):', data);
    } else {
      console.error('Characteristic does not support write or write without response');
    }
  } catch (error) {
    console.error('Failed to write data to characteristic:', error);
  }
};

const RicConnectDevice = ({ side, onConnectWithDevice, Component, onLoadingChange, onEnableChange, onWriteFunctionEnabled, onDisconnect, fitting }) => {
  const [leftVolume, setLeftVolume] = useState(40);
  const [rightVolume, setRightVolume] = useState(40);
  const [leftDeviceConnected, setLeftDeviceConnected] = useState(false);
  const [rightDeviceConnected, setRightDeviceConnected] = useState(false);
  const [leftDeviceCharacteristic, setLeftDeviceCharacteristic] = useState(null);
  const [rightDeviceCharacteristic, setRightDeviceCharacteristic] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Checking Browser Support");
  const [deviceInfo, setDeviceInfo] = useState({ name: "", id: "" });
  const [deviceObj, setDeviceObj] = useState(null);
  const [leftMode, setLeftMode] = useState(null);
  const [rightMode, setRightMode] = useState(null);
  const [leftNoiseReductionEnabled, setLeftNoiseReductionEnabled] = useState(false);
  const [rightNoiseReductionEnabled, setRightNoiseReductionEnabled] = useState(false);
  const [leftFeedBackCancellationEnabled, setLeftFeedBackCancellationEnabled] = useState(false);
  const [rightFeedBackCancellationEnabled, setRightFeedBackCancellationEnabled] = useState(false);
  const [data, setData] = useState([]);
  const [connected, setConnected] = useState();
  const [enabled, setEnabled] = useState(false);

  const dispatch = useDispatch()
  const toggleNoiseReduction = async (side) => {
    if (side === "Left") {
      setLeftNoiseReductionEnabled(!leftNoiseReductionEnabled);
      if (leftDeviceCharacteristic) {
        let noiseReductionData;
        if (!leftNoiseReductionEnabled) {
          noiseReductionData = [
            '87 03 01 00 03',
            '87 03 01 01 03',
            '87 03 01 02 03'
          ];
        } else {
          noiseReductionData = [
            '87 03 01 00 00',
            '87 03 01 01 00',
            '87 03 01 02 00'
          ];
        }
        for (const data of noiseReductionData) {
          await writeDataToCharacteristic(leftDeviceCharacteristic, data);
        }
      }
    } else {
      setRightNoiseReductionEnabled(!rightNoiseReductionEnabled);
      if (rightDeviceCharacteristic) {
        let noiseReductionData;
        if (!rightNoiseReductionEnabled) {
          noiseReductionData = [
            '87 03 02 00 03',
            '87 03 02 01 03',
            '87 03 02 02 03'
          ];
        } else {
          noiseReductionData = [
            '87 03 02 00 00',
            '87 03 02 01 00',
            '87 03 02 02 00'
          ];
        }
        for (const data of noiseReductionData) {
          await writeDataToCharacteristic(rightDeviceCharacteristic, data);
        }
      }
    }
  };

  const toggleFeedbackCancellation = async () => {
    if (side === "Left") {
      setLeftFeedBackCancellationEnabled(!leftFeedBackCancellationEnabled);
      if (leftDeviceCharacteristic) {
        let feedbackCancellationData;
        if (!leftFeedBackCancellationEnabled) {
          feedbackCancellationData = [
            '89 03 01 00 01',
            '89 03 01 01 01',
            '89 03 01 02 01'
          ];
        } else {
          feedbackCancellationData = [
            '89 03 01 00 00',
            '89 03 01 01 00',
            '89 03 01 02 00'
          ];
        }
        for (const data of feedbackCancellationData) {
          await writeDataToCharacteristic(leftDeviceCharacteristic, data);
        }
      }
    } else {
      setRightFeedBackCancellationEnabled(!rightFeedBackCancellationEnabled);
      if (rightDeviceCharacteristic) {
        let feedbackCancellationData;
        if (!rightFeedBackCancellationEnabled) {
          feedbackCancellationData = [
            '89 03 02 00 01',
            '89 03 02 01 01',
            '89 03 02 02 01'
          ];
        } else {
          feedbackCancellationData = [
            '89 03 02 00 00',
            '89 03 02 01 00',
            '89 03 02 02 00'
          ];
        }
        for (const data of feedbackCancellationData) {
          await writeDataToCharacteristic(rightDeviceCharacteristic, data);
        }
      }
    }
  };

  const increaseLeftVolume = async () => {
    setLeftVolume((prevVolume) => Math.min(prevVolume + 10, 100));
    if (leftDeviceCharacteristic) {
      console.log('Writing data to left device: 83 03 01 10 03');
      await writeDataToCharacteristic(leftDeviceCharacteristic, '83 03 01 10 03');
    }
  };

  const decreaseLeftVolume = async () => {
    setLeftVolume((prevVolume) => Math.max(prevVolume - 10, 0));
    if (leftDeviceCharacteristic) {
      console.log('Writing data to left device: 83 03 01 30 03');
      await writeDataToCharacteristic(leftDeviceCharacteristic, '83 03 01 30 03');
    }
  };

  const increaseRightVolume = async () => {
    setRightVolume((prevVolume) => Math.min(prevVolume + 10, 100));
    if (rightDeviceCharacteristic) {
      console.log('Writing data to right device: 83 03 02 10 03');
      await writeDataToCharacteristic(rightDeviceCharacteristic, '83 03 02 10 03');
    }
  };

  const decreaseRightVolume = async () => {
    setRightVolume((prevVolume) => Math.max(prevVolume - 10, 0));
    if (rightDeviceCharacteristic) {
      console.log('Writing data to right device: 83 03 02 30 03');
      await writeDataToCharacteristic(rightDeviceCharacteristic, '83 03 02 30 03');
    }
  };

  const setMode = async (side, mode) => {
    if (side === "Left") {
      setLeftMode(mode);
      if (leftDeviceCharacteristic) {
        let modeData;
        if (mode === 1) {
          modeData = '85 03 01 00 01';
        } else if (mode === 2) {
          modeData = '85 03 01 00 02';
        } else if (mode === 3) {
          modeData = '85 03 01 00 03';
        }
        await writeDataToCharacteristic(leftDeviceCharacteristic, modeData);
      }
    } else {
      setRightMode(mode);
      if (rightDeviceCharacteristic) {
        let modeData;
        if (mode === 1) {
          modeData = '85 03 02 00 01';
        } else if (mode === 2) {
          modeData = '85 03 02 00 02';
        } else if (mode === 3) {
          modeData = '85 03 02 00 03';
        }
        await writeDataToCharacteristic(rightDeviceCharacteristic, modeData);
      }
    }
  };

// Add state for feedback cancellation
// Update the connectDevice function to read noise reduction and feedback cancellation status
const connectDevice = async () => {
 
  console.log("side", side)
  try {
    setLoadingMessage("Connecting Device...");
    setLoading(true);

    const serviceUuid = "0000fff0-0000-1000-8000-00805f9b34fb";
    const characteristicUuidReadNotify = "0000fff1-0000-1000-8000-00805f9b34fb";
    const characteristicUuidReadWrite = "0000fff2-0000-1000-8000-00805f9b34fb";
    
    console.log("Requesting Bluetooth device...");
    const device = await navigator.bluetooth
    .requestDevice({
      filters: [
        { manufacturerData: [{ companyIdentifier: parseInt("0x6C42") }] },
      ],
      optionalServices: [serviceUuid],
    })

    
      .catch((e) => {
        console.error("Failed to request device:", e);
        setLoadingMessage("Failed to connect: " + (e.message ?? "User cancelled the request"));
        setLoading(false);
        return null;
      });

    if (!device) {
      setLoadingMessage("No device selected.");
      setLoading(false);
      return;
    }

    console.log('Device found:', device.name);
    const server = await device.gatt.connect();
    console.log('GATT server connected:', server);

    console.log('Getting service and characteristics...');
    const service = await server.getPrimaryService(serviceUuid);
    const characteristicReadNotify = await service.getCharacteristic(characteristicUuidReadNotify);
    const characteristicReadWrite = await service.getCharacteristic(characteristicUuidReadWrite);
    console.log('Connected to characteristics:', characteristicReadNotify, characteristicReadWrite);

    // Log characteristic properties
    console.log('Characteristic Read/Notify properties:', characteristicReadNotify.properties);
    console.log('Characteristic Read/Write properties:', characteristicReadWrite.properties);

    // Update the connected state
    if (side === "Left") {
      setLeftDeviceConnected(true);
      setLeftDeviceCharacteristic(characteristicReadWrite);
      setConnected(true)
    } else {
      setRightDeviceConnected(true);
      setRightDeviceCharacteristic(characteristicReadWrite);
      setConnected(true)
    }
    setLoadingMessage("Device connected successfully");
    setLoading(false);

  

    // Set device info and handle disconnection
    setDeviceInfo({
      name: device.name,
      id: device.id,
    });

    device.ongattserverdisconnected = () => {
      console.log('Device disconnected');
      if (side === "Left") {
        setLeftDeviceConnected(false);
      } else {
        setRightDeviceConnected(false);
      }
      setLoadingMessage("Device disconnected");
    };
    setDeviceObj(device);


    // Subscribe to notifications for reading the mode, volume, noise reduction, and feedback cancellation
    // characteristicReadNotify.addEventListener('characteristicvaluechanged', (event) => {
    //   const value = event.target.value;
    //   const decodedValue = Array.from(new Uint8Array(value.buffer))
    //     .map(byte => byte.toString(16).padStart(2, '0'))
    //     .join(' ');
    //   console.log(`Received notification from ${side} device:`, decodedValue);

      // Set the mode based on the read data
      // if (decodedValue.startsWith('84 03 01 00') || decodedValue.startsWith('84 03 02 00')) {
      //   const mode = parseInt(decodedValue.slice(-1), 16);
      //   setMode(side, mode);
      // }

      // // Set the volume based on the read data
     

      // // Set the noise reduction based on the read data
      // if (decodedValue.startsWith('86 03 01') || decodedValue.startsWith('86 03 02')) {
      //   const noiseReductionEnabled = decodedValue.endsWith('03');
      //   if (side === "Left") {
      //     setLeftNoiseReductionEnabled(noiseReductionEnabled);
      //   } else {
      //     setRightNoiseReductionEnabled(noiseReductionEnabled);
      //   }
      // }

      // Set the feedback cancellation based on the read data
    //   if (decodedValue.startsWith('88 03 01') || decodedValue.startsWith('88 03 02')) {
    //     const feedbackCancellationEnabled = decodedValue.endsWith('03');
    //     if (side === "Left") {
    //       setLeftFeedBackCancellationEnabled(feedbackCancellationEnabled);
    //     } else {
    //       setRightFeedBackCancellationEnabled(feedbackCancellationEnabled);
    //     }
    //   }
    // });

    // await characteristicReadNotify.startNotifications();
    // console.log('Notifications started for characteristicReadNotify');

    // Send the code to request the current mode
    // let readModeData;
    // if (side === "Left") {
    //   readModeData = '84 02 01 00';
    // } else {
    //   readModeData = '84 02 02 00';
    // }
    // await writeDataToCharacteristic(characteristicReadWrite, readModeData);
    // console.log(`Sent code to request current mode: ${readModeData}`);


    // // Send the code to request the current noise reduction status
    // let readNoiseReductionData;
    // if (side === "Left") {
    //   readNoiseReductionData = '86 02 01 00';
    // } else {
    //   readNoiseReductionData = '86 02 02 00';
    // }
    // await writeDataToCharacteristic(characteristicReadWrite, readNoiseReductionData);
    // console.log(`Sent code to request current noise reduction status: ${readNoiseReductionData}`);

    // // Send the code to request the current feedback cancellation status
    // let readFeedbackCancellationData;
    // if (side === "Left") {
    //   readFeedbackCancellationData = '88 02 01 00';
    // } else {
    //   readFeedbackCancellationData = '88 02 02 00';
    // }
    // await writeDataToCharacteristic(characteristicReadWrite, readFeedbackCancellationData);
    // console.log(`Sent code to request current feedback cancellation status: ${readFeedbackCancellationData}`);




    // dispatch(readNoiseReduction(LISTENING_SIDE.RIGHT, deviceObj));
 
    // Set the mid volume level
    // let setMidVolumeData;
    // if (side === "Left") {
    //   setMidVolumeData = '83 03 01 00 12';
    // } else {
    //   setMidVolumeData = '83 03 02 00 15';
    // }
    // await writeDataToCharacteristic(characteristicReadWrite, setMidVolumeData);
    // console.log(`Sent code to set mid volume level: ${setMidVolumeData}`);

    let setModeData;
    if (side === "Left") {
      setModeData = '85 03 01 00 01';
    } else {
      setModeData = '85 03 02 00 01';
    }
    await writeDataToCharacteristic(characteristicReadWrite, setModeData);

    
    let setNoiseReductionOn;
    if (side === "Left") {
      setNoiseReductionOn = '87 03 01 00 01';
    } else {
      setNoiseReductionOn = '87 03 02 00 01';
    }
    await writeDataToCharacteristic(characteristicReadWrite, setNoiseReductionOn);

    console.log(`Sent code to set noise reduction on: ${setNoiseReductionOn}`);

  } catch (error) {
    console.error('Error:', error);
    setLoadingMessage("Failed to connect: " + error.message);
    setLoading(false);
    if (side === "Left") {
      setLeftDeviceConnected(false);
    } else {
      setRightDeviceConnected(false);
    }
  }
};

// useEffect(() => {
//   if (deviceObj) {
//     dispatch(readNoiseReduction(LISTENING_SIDE.LEFT, deviceObj));
//     dispatch(readNoiseReduction(LISTENING_SIDE.RIGHT, deviceObj));
//   }
// }, [deviceObj]); // Only run when deviceObj changes


const disconnect = (side) => {
    console.log("disconnected");
    if (deviceObj?.gatt?.connected) {
      console.log("hard disconnect");
      deviceObj.gatt?.disconnect?.();
    } else {
      setLoadingMessage("already Disconnected..");
    }
    if (side === "Left") {
      setLeftDeviceConnected(false);
    } else {
      setRightDeviceConnected(false);
    }
    setDeviceInfo({});
    setDeviceObj(null);
    setLoadingMessage("");
  };

  useEffect(() => {
    if (window.navigator && window.navigator.bluetooth) {
      setLoading(false);
      setLoadingMessage("");
      setEnabled(true);
    }
  }, []);

  useEffect(() => {
    // if (!loading)
    onEnableChange(enabled);
  }, [enabled]);


  const handleLeftVolumeChange = async (event, newValue) => {
    setLeftVolume(newValue);
    if (leftDeviceCharacteristic) {
      const volumeHex = newValue.toString(16).padStart(2, '0');
      const data = `83 03 01 ${volumeHex} 03`;
      await writeDataToCharacteristic(leftDeviceCharacteristic, data);
    }
  };

  const handleRightVolumeChange = async (event, newValue) => {
    setRightVolume(newValue);
    if (rightDeviceCharacteristic) {
      const volumeHex = newValue.toString(16).padStart(2, '0');
      const data = `83 03 02 ${volumeHex} 03`;
      await writeDataToCharacteristic(rightDeviceCharacteristic, data);
    }
  };
console.log("DeviceObj", deviceObj);
  useEffect(() => {
    if (connected && deviceInfo.id != "")
      onConnectWithDevice(data, deviceInfo,deviceObj ,() => disconnect(true));
  }, [connected]);


  return (
      <Component
        loading={loading}
        connected={side === "Left" ? leftDeviceConnected : rightDeviceConnected}
        onClick={connectDevice}
        disconnect={() => disconnect(true)}
        deviceSide={fitting?.device_side}
      />
    );
};

export default RicConnectDevice ;