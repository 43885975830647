import { memo, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { callApiAction } from "../../store/actions/commonAction";
import { getUserByIdApi } from "../../apis/user.api";
import PatientDetailedViewCommonUI from "./PatientDetailedViewCommonUI";
import { getPatientByIdApi } from "../../apis/patient.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { closeModal } from "../../store/actions/modalAction";
import {
  changeDeviceSelection,
  disconnectDevice,
  resetToDefault,
} from "../../store/actions/fittingAction";
import { LISTENING_SIDE } from "../../utils/patient.constants";

const PatientDetailedViewCommonController = ({ id, getFun }) => {
  const { fitting, fittingLeft } = useSelector((state) => state);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const title = "Patient details";
  const modalKey = "patient-modal";
  const getByIdApi = getFun ?? getPatientByIdApi;

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({});

  useEffect(() => {
    if (fitting.connected) {
      dispatch(disconnectDevice(fitting.device_side, true));
    } else {
      dispatch(resetToDefault(fitting.device_side));
    }

    if (fittingLeft.connected) {
      dispatch(disconnectDevice(fittingLeft.device_side, true));
    } else {
      dispatch(resetToDefault(fittingLeft.device_side));
    }
  }, []);

  const [deviceFetch, setDeviceFetch] = useState(false);

  // const fetchById = (id) => {
  //   setLoading(true);
  //   dispatch(
  //     callApiAction(
  //       async () => await getByIdApi({ id }),
  //       async (response) => {
  //         setData({ ...response });
  //         setDeviceFetch(true);
  //         setLoading(false);
  //       },
  //       (err) => {
  //         setLoading(false);
  //       }
  //     )
  //   );
  // };

  useEffect(() => {
    console.log("this is reducer", fitting.mode_data, fittingLeft.mode_data);
    console.log("device_type", fitting.device_type)
  }, []);

  useEffect(() => {
    if (deviceFetch) {
      if (data?.right_fitted_device?.device_type) {
        dispatch(
          changeDeviceSelection(
            data?.right_fitted_device?.device_type,
            LISTENING_SIDE.RIGHT
          )
        );
      }

      if (data?.left_fitted_device?.device_type) {
        dispatch(
          changeDeviceSelection(
            data?.left_fitted_device?.device_type,
            LISTENING_SIDE.LEFT
          )
        );
      }

      setDeviceFetch(false);
    }
  }, [deviceFetch]);

  const onEdit = (e, step) => {
    let route = "/patient-details/" + params.id + "/update";

    if (step) {
      route += "/audiogram";
      navigate(route);
    }
  };
  const onNewFitting = () => {
    navigate("/patient-details/" + params.id + "/new-fitting");
  };

  const onRicNewFitting = () => {
    navigate("/patient-details/ric-new-fitting");
  };

  // useEffect(() => {
  //   console.log("this is params id", params.id);
  //   if (params.id) fetchById(params.id);
  // }, [params.id]);

  return (
    <PatientDetailedViewCommonUI
      onNewFitting={onNewFitting}
      onRicNewFitting={onRicNewFitting}
      onEdit={onEdit}
      modalKey={modalKey}
      title={title}
      loading={loading}
      data={data}
      setData={setData}
    />
  );
};
export default memo(PatientDetailedViewCommonController);
