import {
    Box,
    Button,
    ButtonBase,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    styled,
    useTheme,
    Divider,
    IconButton,
    Select,
    MenuItem,
  } from "@mui/material";
  
  import { useDispatch, useSelector } from "react-redux";
  import VolumeUpIcon from "@mui/icons-material/VolumeUp";
  import VolumeOffIcon from "@mui/icons-material/VolumeOff";
  import IncreasDecreaseButton from "../../../components/button/IncreaseDecreaseButton";
  import BothSideSlider from "../../../components/button/BothSiderSlider";
  import FittingChartArea from ".././FittingChartArea";
  import { openModal } from "../../../store/actions/modalAction";
  import AdvanceFittingController from ".././AdvanceFittingController";
  import {
    applyFeedbackCancellation,
    applyWinNoiseBlock,
    autofitDevice,
    changeByteValue,
    changeCompression,
    changeDeviceArray,
    changeDeviceSideSelection,
    changeMasterGain,
    changeMultipleByteValue,
    changeNoiseReduction,
    changeProfileId,
    changeRemarks,
    changeSharpness,
    changeSpeechRedcution,
    changeWindNoiseReduction,
    disconnectDevice,
    readDataFromDevice,
    resetDevice,
  } from "../../../store/actions/fittingAction";
  import { memo, useState } from "react";
  import SubmitButton from "../../../components/button/SubmitButton";
  import { callApiAction } from "../../../store/actions/commonAction";
  import { callSnackBar } from "../../../store/actions/snackbarAction";
  import {
    addPatientProfileApi,
    updatePatientProfileField,
  } from "../../../apis/patientprofile.api";
  import { useNavigate, useParams } from "react-router-dom";
  import { SNACK_BAR_VARIETNS, USER_ROLES, actions } from "../../../utils/constants";
  import {
    changeOriginalValueFromMediun,
    findMediun,
    mapValues,
  } from "../../../utils/helper";
  import { GAIN_POINTS } from "../../../utils/j10.helper";
  import { DEVICES, LISTENING_SIDE } from "../../../utils/patient.constants";

  import { Slider } from "@mui/material";
import RicFittingChartArea from "./RicFittingChartArea";
import WriteRicDataToDevice from "./WriteRicDataToDevice";
import { changeRicFeedbackCancellation, changeRicNoiseReduction } from "../../../store/actions/ricFittingActions";

  const StyledButton = styled(ButtonBase)(({ theme, active }) => ({
    padding: theme.spacing(2),
    display: "flex",
    flex: 1,
    color: active ? "unset" : theme.palette.light.main,
    border: "1px solid " + theme.palette.primary.main,
  
    borderLeft: 0,
    borderRight: 0,
    borderBottom: active ? 0 : 1,
    // borderTopLeftRadius: theme.shape.borderRadius *2,
    // borderTopRightRadius: theme.shape.borderRadius *2,
    background: active ? theme.palette.light.main : theme.palette.primary.main,
  }));
  const ActionInputButtons = memo(({ title, ...rest }) => {
    const theme = useTheme();
    return (
      <Box
        sx={{ width: "100%", borderLeft: "1px solid" + theme.palette.grey[300] }}
        pl={3}
      >
        <Typography
          variant="body1"
          lineHeight="100%"
          color="dark.main"
          fontWeight={500}
          mb={2}
        >
          {title}
        </Typography>
        <IncreasDecreaseButton {...rest} />
      </Box>
    );
  });
  const ActionInputSliders = ({ title, ...rest }) => {
    const theme = useTheme();
    return (
      <Box sx={{ width: "6vw" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
            // width: "100%",
            // marginBottom: "-3px",
          }}
        >
          <Typography
            variant="h6"
            display={"flex"}
            justifyContent={"center"}
            color="dark.main"
            fontWeight={500}
          >
            {title}
          </Typography>
          <Typography>{rest.value}</Typography>
        </Box>
        <Box
          mt={2}
          sx={{
            display: "flex",
            alignItems: "space-between",
            justifyContent: "center",
            flexDirection: "row",
            // width: "100%",
            // marginBottom: "-3px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }} ml={4}>
              <BothSideSlider {...rest} />
            </Box>
            <Box
              sx={{
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" color="grey">
                {10}
              </Typography>
              <Typography variant="h6" color="grey">
                {-10}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  
  const marks = [
    { value: 0, label: "Off" },
    { value: 1, label: "Low" },
    { value: 2, label: "Medium" },
    { value: 3, label: "High" },
    { value: 4, label: "Very High" },
    { value: 5, label: "Max" },
  ];
  
  const CustomSlider = styled(Slider)(({ side }) => ({
    backgroundColor: "white",
  
    height: 0.1,
    // '& .MuiSlider-track': {
    //   border: 'black  ',
    // },
    "& .MuiSlider-thumb": {
      height: 20,
      width: 20,
      boxShadow: "none",
      backgroundColor:
        side
          ? "rgba(238, 210, 210, 0.5 )"
          : "rgba(229, 238, 244, 0.5)",
      border: side ? "4px solid  #C24747" : "4px solid #1265A1",
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      left: "calc(-50% + 4px)",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#DDDDDD",
      height: 10,
      width: 10,
      borderRadius: 20,
  
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "white",
      },
    },
    "& .MuiSlider-markLabel": {
      color: "#808080",
      fontSize: "1vw",
      // fontWeight: 'bold',
      fontFamily: "League spartan",
    },
    "& .MuiSlider-markLabelActive": {
      color: "#2D3B67",
      // fontWeight: 'bold',
      fontFamily: "League spartan",
    },
  }));
  

  
  
  const RicBasicFittingAreaController = ({ mutedHandle, onAutoFit }) => {
    const params = useParams();
    const theme = useTheme();
    const { user, fitting, fittingLeft, ricLeftFitting, ricRightFitting } = useSelector((state) => state);
    const dispatch = useDispatch();
    console.log("ricccc", ricRightFitting)
    const [autoFitLeftloading, setAutoFitLeftloading] = useState(false);
    const [autoFitRightloading, setAutoFitRightloading] = useState(false);
  
    
  
    const [readLoading, setReadLoading] = useState(false);
    const [saveLoadin, setSaveLoading] = useState(false);
    const [completeLoading, setCompleteLoading] = useState(false);
  

    const writeDataToDevice = async (command, side) => {
      try {
        const deviceObj = side === LISTENING_SIDE.LEFT ? fittingLeft.deviceObj : fitting.deviceObj;
    const device = deviceObj.device?.device || deviceObj.device || deviceObj;

        if (!device || !device.gatt) {
          console.error("No valid Bluetooth device found!");
          return;
        }
    
        const SERVICE_UUID = "0000fff0-0000-1000-8000-00805f9b34fb";
        const CHARACTERISTIC_UUID = "0000fff2-0000-1000-8000-00805f9b34fb";
    
        const service = await device.gatt.getPrimaryService(SERVICE_UUID);
        const characteristic = await service.getCharacteristic(CHARACTERISTIC_UUID);
    
        // Ensure Command is Properly Converted
        const dataArray = command
          .split(" ")
          .map(byte => parseInt(byte, 16))
          .filter(byte => !isNaN(byte)); // Removes invalid numbers
    
        if (dataArray.length === 0) {
          console.error("Invalid command format:", command);
          return;
        }
    
        const dataBuffer = new Uint8Array(dataArray);
    
        //  Write to Device
        await characteristic.writeValue(dataBuffer);
        console.log(`Data successfully written to ${side} device:`, command);
      } catch (error) {
        console.error(`Error writing data to ${side} device:`, error);
      }
    };
    
    
    
    
    
    

    const handleSetVolume = async (volume) => {
      const volumeHex = volume.toString(16).padStart(2, '0');
      const data = `83 03 02 ${volumeHex} 03`;  // Always use the right side command
      await writeDataToDevice(data, 'Left');  // Always write to the right side
    };
  
    const [tab, setTab] = useState(0);
  
    ///for setting last amount
    // const [edgeVal, setEdgeVal] = useState(50);
    const [Link, setLink] = useState(false);
  

    const [Selectitem, setSelectitem] = useState(4);
    const handleChange = (event) => {
      if (event.target.value === 1 || event.target.value === 4) {
        setSelectitem(event.target.value);
      }
    };
  
    const [value, setValue] = useState(2);
  
    const handleChangee = (event, newValue) => {
      setValue(newValue);
    };
  
    const frequencyGridWidth = tab != 1 ? 3 : 6;
    const [sliderValueRight, setSliderValueRight] = useState(0);
    const [sliderValueLeft, setSliderValueLeft] = useState(0);
    
    const handleNoiseReductionChange = async (value, side, deviceObj) => {
      try {
        let command = "";
    
        if (value === 0) {
          // Fix: Ensure Correct OFF Command
          command = side === LISTENING_SIDE.LEFT ? "87 03 01 02 00" : "87 03 02 02 00";
        } else if (value === 1) {
          command = side === LISTENING_SIDE.LEFT ? "87 03 01 00 01" : "87 03 02 00 01";
        }  else if (value === 2) {
          command = side === LISTENING_SIDE.LEFT ? "87 03 01 02 02" : "87 03 02 02 02";
        } else if (value === 3) {
          command = side === LISTENING_SIDE.LEFT ? "87 03 01 02 03" : "87 03 02 02 03";
        }
    
        if (command) {
          console.log(`Sending Noise Reduction command: ${command}`);
          await WriteRicDataToDevice(command, side, fitting, fittingLeft);
        } else {
          console.warn("Invalid noise reduction value:", value);
        }
      } catch (error) {
        console.error("Error changing noise reduction:", error);
      }
    };
    
    


    return (
      <Box>
         
        <Box maxHeight={"60vh"}>
          <Grid xs={12} md={12} container>
            <Grid md={5.7} xs={5.7}>
              <RicFittingChartArea
                mutedHandle={mutedHandle}
                fitting={ricRightFitting}
                edgeVal={ricRightFitting?.eqValues}
              />
            </Grid>
            <Grid md={0.6} xs={0.6}>
              <Box
                sx={{
                  marginTop: "20vh",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Select value={10} sx={{ height: "5vh", width: "5vw" }}>
                    <MenuItem value={10}>
                      <img src={MenuItemgraphImage} alt="SPEAKERIMAGE" />
                    </MenuItem>
                  </Select>
                </Box> */}
                <Box
                  mt={3}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#4A4A4A",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        50dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#969696",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        65dB
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box mr={1}>
                      <Typography
                        sx={{
                          color: "#2D3B67",
                          borderRadius: "86px",
                          width: "1.5vw",
                          height: "4px",
                          background: "#CCCCCC",
                        }}
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          fontFamily: "League spartan",
                          color: "#808080",
                          fontStyle: "normal",
                          fontWeight: "400px",
                        }}
                      >
                        80dB
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid xs={5.7} md={5.7}>
              <RicFittingChartArea
                mutedHandle={mutedHandle}
                fitting={ricLeftFitting}
                edgeVal={ricLeftFitting?.eqValues}
              />
            </Grid>
          </Grid>
          
        </Box>
        <Box
          ml={6}
          mt={2}
          sx={{ height: "40vh", scrollbarWidth: "none" }}
          overflow={"auto"}
        >
          <Grid container xs={12} md={12}>
            <Grid
              xs={5.4}
              md={5.4}
              sx={{ height: "38vh", scrollbarWidth: "none" }}
            >
              {" "}
              {fitting.connected && (
                <Grid container xs={12} md={12}>
                  <Grid xs={3} md={3}>
                    {/* {!autoFitRight ? ( */}
                  
                    {/* <Typography variant="h6">Auto Fit</Typography> */}
                    {/* ) : (
                  <Button 
                    onClick={() => {
                      dispatch(
                        autofitDevice(
                          params.id,
                          () => {
                            dispatch(
                              callSnackBar(
                                "Device fitted as per patient audiogram.",
                                SNACK_BAR_VARIETNS.suceess
                              )
                            );
                          },
                          (err) => {
                            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                          },
                          fitting
                        )
                      );
                      setAutoFitRight(true);
                    }}
                    variant="outlined"
                    sx={{
                      fontSize: "16px",
                      fontFamily: "League spartan",
                      fontWeight: 500,
                      padding: "8px, 4px, 8px, 4px",
                      height: "6vh",
                      width: "7vw",
                      border: "1px solid #2D3B67",
                      backgroundColor: "#EDF0F7",
                    }}
                  >
                    Auto Fit{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668Z"
                        fill="#39AC8A"
                      />
                      <path
                        d="M5 8L7 10L11 6"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                )} */}
                    {/* <Grid > */}


                    


                   
                    {/* </Grid> */}
                  </Grid>
  
                  <Grid md={9} xs={9}>
                    {/* <Grid mr={1} pl={3} sx={{ width: "28vw", border: "1px solid #DDDDDD", borderRadius: "4px",minHeight: "10vh" }}> */}
                    <Box
                      sx={{
                        width: "28vw",
                        // border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                    
                    </Box>
                    {/* </Grid> */}
                    <Box
                      mt={2}
                      sx={{
                        width: "28vw",
                        border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                       <Box>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "12px",
                            fontFamily: "League spartan",
                            fontWeight: "400",
                          }}
                        >
                          Noise Reduction
                        </Typography>
                        <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                        <CustomSlider
  side={LISTENING_SIDE.RIGHT}
  disabled={fitting.read_only}
  track={false}
  value={ricRightFitting.ric_noise_reduction.right}  // Using state
  min={0}
  max={3}
  step={1}
  marks={marks}
  onChange={(event, value) => {
    console.log("Device Object in UI before dispatch:", ricRightFitting.deviceObj);
    
    dispatch(changeRicNoiseReduction(value, LISTENING_SIDE.RIGHT, ricRightFitting.deviceObj));
  }}
  valueLabelDisplay="off"
/>

</Box>


                      </Box>
                    </Box>
                    <Box
                      mt={2}
                      sx={{
                        width: "28vw",
                        // border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                     
                    </Box>
                    <Box
                      mt={2}
                      sx={{
                        width: "28vw",
                        // border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                     
                    </Box>
                   
                  </Grid>
                </Grid>
              )}
            </Grid>
  
            <Grid
              xs={1}
              md={1}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
          
            </Grid>
            {fittingLeft.connected && (
              <Grid
                xs={5.4}
                md={5.4}
                sx={{ height: "45vh", scrollbarWidth: "none" }}
              >
                <Grid container md={12} xs={12}>
                  <Grid xs={3} md={3}>
                    {/* {!autoFitLeft ? ( */}
                   
                    {/* ) : (
                  <Button
                    onClick={() => {
                      dispatch(
                        autofitDevice(
                          params.id,
                          () => {
                            dispatch(
                              callSnackBar(
                                "Device fitted as per patient audiogram.",
                                SNACK_BAR_VARIETNS.suceess
                              )
                            );
                          },
                          (err) => {
                            dispatch(callSnackBar(err, SNACK_BAR_VARIETNS.error));
                          },
                          fittingLeft
                        )
                      );
                      setAutoFitLeft(true);
                    }}
                    variant="outlined"
                    sx={{
                      fontSize: "16px",
                      fontFamily: "League spartan",
                      fontWeight: 500,
                      padding: "8px, 4px, 8px, 4px",
                      height: "6vh",
                      width: "7vw",
                      border: "1px solid #2D3B67",
                      backgroundColor: "#EDF0F7",
                    }}
                  >
                    Auto Fit{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668Z"
                        fill="#39AC8A"
                      />
                      <path
                        d="M5 8L7 10L11 6"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </Button>
                )} */}

              
                 
                    {/* </Grid> */}
                  </Grid>
  
                  <Grid xs={9} md={9}>
                    {/* <Grid mr={1} pl={3} sx={{ width: "28vw", border: "1px solid #DDDDDD", borderRadius: "4px",minHeight: "10vh" }}> */}
                    <Box
                      sx={{
                        width: "28vw",
                        // border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                     
                    </Box>
                    {/* </Grid> */}
                    <Box
                      mt={2}
                      sx={{
                        width: "28vw",
                        border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <Box sx={{ width: "24vw", minWidth: "18vw" }}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: "12px",
                              fontFamily: "League spartan",
                              fontWeight: "400",
                            }}
                          >
                            Noise Reduction
                          </Typography>
                          <CustomSlider
 
  disabled={fitting.read_only}
  track={false}
  value={ricLeftFitting.ric_noise_reduction.left}  // Use direct state
  min={0}
  max={3}
  step={1}
  marks={marks}
  onChange={(event, value) => {
    console.log("Device Object in UI before dispatch:", ricLeftFitting.deviceObj);

    dispatch(changeRicNoiseReduction(value, LISTENING_SIDE.LEFT, ricLeftFitting.deviceObj || {}));
  }}
  valueLabelDisplay="off"
/>

                        </Box>
                      </Box>
                    </Box>
                    <Box
                      mt={2}
                      sx={{
                        width: "28vw",
                        // border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                     
                    </Box>
                    <Box
                      mt={2}
                      sx={{
                        width: "28vw",
                        // border: "1px solid #DDDDDD",
                        borderRadius: "4px",
                        minHeight: "10vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      
                    </Box>
                   
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    );
  };
  
  export default RicBasicFittingAreaController;
  
  // IGi=0 for Hi<20dB HL
  // IGi=0.6(Hi-20) for 20≤Hi≤60dB HL
  // IGi=0.8Hi-23 for Hi > 60dB HL
  